import noUiSlider from 'nouislider';

function findGetParameter(parameterName) {
  var result = [],
    tmp = [];
  location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=');
      if (tmp[0] === parameterName) result = [decodeURIComponent(tmp[1])];
    });
  return result;
}

function inCategory(productCategories, modelCategories) {
  if (Array.isArray(modelCategories)) {
    return modelCategories.length === 0 || productCategories.some(r => modelCategories.map(c => parseInt(c)).includes(r));
  } else {
    return !modelCategories || productCategories.indexOf(parseInt(modelCategories)) > -1;
  }
}

export default (products) => ({
  products: products,
  category: findGetParameter('category'),
  subCategory: findGetParameter('subCategory'),
  trackSystemCategory: findGetParameter('trackSystem'),
  frontRearCategory: findGetParameter('frontRear'),
  leftRightCategory: findGetParameter('leftRight'),
  minPrice: 0,
  maxPrice: 0,
  sortProducts: 'bestseller',
  paging: 1,
  itemsPerPage: 12,
  showFilters: false,

  init () {
    noUiSlider.create(this.$refs.priceSlider, {
      start: [0, 100],
      range: {
        'min': 0,
        'max': 100
      },
      tooltips: { to: function(value) {
        return "$" + value.toFixed(2);
      } },
    });

    const onProductsChange = () => {
      this.minPrice = this.productsMinPrice;
      this.maxPrice = this.productsMaxPrice;
      this.$refs.priceSlider.noUiSlider.updateOptions({
        start: [this.minPrice, this.maxPrice],
        range: {
          min: this.minPrice,
          max: this.maxPrice
        }
      });
    };

    this.$refs.priceSlider.noUiSlider.on('update', (values, handle) => {
      this.minPrice = values[0];
      this.maxPrice = values[1];
      console.log('minPrice', this.minPrice);
      console.log('maxPrice', this.maxPrice);
    });

    this.$watch('filteredProducts', onProductsChange);
    onProductsChange();
  },

  get filteredProducts() {
    return this.products
      .filter(
        product =>
          inCategory(product.productCategory, this.category) &&
          inCategory(product.productSubCategory, this.subCategory) &&
          inCategory(product.productTrackSystem, this.trackSystemCategory) &&
          inCategory(product.productFrontRear, this.frontRearCategory) &&
          inCategory(product.productLeftRight, this.leftRightCategory)
      );
  },

  get displayedProducts() {
    return this.filteredProducts
      .filter(product => {
        const productPrice = product.priceAmount;
        return productPrice >= this.minPrice && productPrice <= this.maxPrice;
      })
      .sort((a, b) => {
        if (this.sortProducts === 'title-ascending') {
          return a.title.localeCompare(b.title);
        } else if (this.sortProducts === 'title-descending') {
          return b.title.localeCompare(a.title);
        } else if (this.sortProducts === 'price-ascending') {
          return a.priceAmount - b.priceAmount;
        } else if (this.sortProducts === 'price-descending') {
          return b.priceAmount - a.priceAmount;
        }
        return a.order - b.order;
      });
  },

  get paginatedProducts() {
    return this.displayedProducts.slice(0, this.paging * this.itemsPerPage);
  },

  get productsMinPrice() {
    return Math.min(...this.filteredProducts.map(p => p.priceAmount));
  },

  get productsMaxPrice() {
    return Math.max(...this.filteredProducts.map(p => p.priceAmount));
  }
});
